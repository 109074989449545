
div.swiper-button-next{
    /* color: #ffffff; */
    border-radius: 50%;
    overflow: hidden;
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    color: black;
    margin-right: 10px;
}
div.swiper-button-prev{
    /* color: #ffffff; */
    border-radius: 50%;
    overflow: hidden;
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    color: black;
    margin-right: 10px;
    color: black;
}
.swiper-pagination-bullet-active{
    background: #ffffff;
}
